@import "../../style-constants/colors.scss";

.about-me-container {
  color: $color5;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("https://hosting.photobucket.com/images/i/awkwardestawkward/(edited)_(edited)_tl_(7).png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5em !important;
}
