@import "../../style-constants/colors.scss";

.app-nav {
  background-color: $color5;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  .nav-left {
    .nav-brand {
      #nav-logo {
        height: 1.5rem;
        padding-right: 0.5rem;
        align-self: center;
      }
      #instagram-logo {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;
      }
    }
  }
  .nav-right {
    .nav-toggle {
    }
    .nav-inner {
      .nav-item {
        a {
          color: black !important;
          text-decoration: none !important;
        }
        margin-right: 1em;
      }
    }
  }
}

.nav-item {
  a {
    color: black !important;
    text-decoration: none !important;
  }
  display: inline-flex;
  margin-right: 1em;
}
