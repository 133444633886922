@import "./style-constants/colors.scss";

html {
  background-color: $color6;
}

.app-container {
  color: white;
  font-family: Optima;
  background-color: $color6;
  width: 100% !important;
}

.app-root {
  background-color: $color6;
}

#root {
  background-color: $color6;
}
