$color1: #42354c;
$color2: #354c3c;
$color3: #6a1d2f;
$color4: #9b461f;
$color5: #dea450;

.app-footer {
  background-color: $color5;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  .footer-left {
    .footer-brand {
      color: black;
    }
  }
  .footer-right {
    .footer-inner {
      .footer-item {
        a {
          color: black !important;
          text-decoration: none !important;
        }
        margin-right: 1em;
      }
    }
  }
}
