.location-div {
  width: 100% !important;
  @media (max-width: 1920px) {
    width: 1000px !important;
  }
  @media (max-width: 1500px) {
    width: 700px !important;
  }
  @media (max-width: 1000px) {
    width: 500px !important;
  }
  @media (max-width: 700px) {
    width: 300px !important;
  }
}
