@import "../../../../style-constants/colors.scss";

.home-gallery-container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 2px solid $color5;
  border-bottom: 2px solid $color5;
  .home-gallery-card {
    background-color: transparent;
    border: transparent;
    margin: 0;
    padding: 0;
    .home-gallery-card-body {
      padding: 0;
      #gallery-item {
        height: 15.25rem;
        transition: 200ms;
      }
    }
  }
}

.home-gallery-modal {
  border: transparent;
  .modal-content {
    border: transparent;
    background-color: transparent;
    .home-gallery-modal-header.modal-header {
      border: transparent;
      button.btn-close {
        font-size: x-large;
        font-weight: 1000;
        color: $color5;
      }
    }
    .home-gallery-modal-body {
      #gallery-modal-item {
        height: 40rem;
        max-width: 700px;
      }
    }
  }
}
